<template lang="">
<div class="cusOverview2" style="height:100%">
    <div class="handleBox flex flex-jc-sb flex-ai-c" style="margin:0 15px;">
        <p class="title">统计概况 ></p>
        <div class="flex flex-ai-c">
            <el-radio-group v-model="radio" style="margin-right:10px">
                <el-radio label="year">年</el-radio>
                <!-- <el-radio label="quarter">季度</el-radio> -->
                <el-radio label="month">月</el-radio>
                <el-radio label="week">周</el-radio>
            </el-radio-group>

            <el-date-picker v-model="inputyear" size="mini" type="year" :clearable="false" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getData()" v-show="radio=='year'">
            </el-date-picker>

            <!-- <quarterDate v-show="radio=='quarter'" :getValue="inputquarter" :defaultValue="defaultValue"></quarterDate> -->

            <el-date-picker v-model="inputmonth" size="mini" type="month" :clearable="false" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getData()" v-show="radio=='month'">
            </el-date-picker>

            <el-date-picker v-model="inputweek" size="mini" type="week" :clearable="false" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getData()" v-show="radio=='week'">
            </el-date-picker>
        </div>
    </div>
    <div class="box" style="height:calc(100% - 60px)">
        <div class="top">
            <el-row :gutter="10" v-if="detail.tongji">
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                    <div class="sss" style="width:100%" @click="goCustomer()">
                        <img src="../assets/kh.png" alt="">
                        <div class="">
                            <span>客户数</span>
                            <p><span>{{detail.tongji.user_count}}</span>个</p>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                    <div class="sss" style="width:100%" @click="goOrder()">
                        <img src="../assets/xm.png" alt="">
                        <div class="">
                            <span>订单数</span>
                            <p><span>{{detail.tongji.order_count}}</span>个</p>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                    <div class="sss" style="width:100%" @click="goOrder()">
                        <img src="../assets/xmje.png" alt="">
                        <div class="">
                            <span>订单金额</span>
                            <p><span>{{detail.tongji.amount}}</span>元</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="centerbox">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <div class="t1">
                        <div class="ovtitle">
                            <img src="../assets/xf.jpg" alt="">
                            <span>销售站点订单金额对比</span>
                        </div>
                        <div id="bar" :style="{width: '98%', height: '90%'}"></div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <div class="t2">
                        <div class="ovtitle">
                            <img src="../assets/xs.jpg" alt="">
                            <span>各品类月销售金额统计</span>
                        </div>
                        <div id="pie" :style="{width: '98%', height: '90%'}"></div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</div>
</template>

<script>
var echarts = require('echarts');
// import quarterDate from "../../components/quarterDate.vue";
export default {
    // components: {
    //     quarterDate
    // },
    data: function() {
        return {
            radio: 'month', //年月周-默认周
            input: '', //时间选择框
            detail: {},
            // tablelength: '',

            inputyear: '',
            inputmonth: '',
            inputweek: '',
            inputquarter: '', //季度

            defaultValue: '', //默认
            customerBarChart: {}, //客户柱形图
            bobaolist: [],
            projectBarChart: [],
            arr:[],
            arr2:[]
        }
    },
    props: ['isCollapse'],
    mounted: function() {
        // 首次登录成功后刷新页面防止接口header取不到 登录超时
        if (this.$route.params.form == 'login') {
            this.$router.go(0)
        }
        this.init();
        window.onresize = () => { //  根据窗口大小调整曲线大小
            let myChart = echarts.init(document.getElementById('pie'));
            myChart.resize();
            let myChartbar = echarts.init(document.getElementById('bar'));
            myChartbar.resize();
        }
    },
    destroyed() {
        window.onresize = null;
    },
    watch: {
        // 'tablelength': function() {
        //     this.getData();
        // },
        isCollapse() {
            setTimeout(() => {
                let myChart = echarts.init(document.getElementById('pie'));
                myChart.resize();
                let myChartbar = echarts.init(document.getElementById('bar'));
                myChartbar.resize();
            }, 200)
        },
        'radio': function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth1 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth1, 1)
                this.inputmonth = this.dataFormat(firstDay2);
            } else if (this.radio == 'week') {
                var current = new Date();
                var currentyear = current.getFullYear();
                var currentMonth2 = current.getMonth();
                var currentDay = current.getDay();
                var currentDate = current.getDate();
                var firstweek = new Date(currentyear, currentMonth2, currentDate - currentDay + 1);
                this.inputweek = this.dataFormat(firstweek);
            } else if (this.radio == 'year') {
                var myDate2 = new Date();
                this.inputyear = String(myDate2.getFullYear());
            } else if (this.radio == 'quarter') {
                var date2 = new Date();
                var nowYear = date2.getFullYear();
                var nowMonth = date2.getMonth(); //当前月
                var quarterStartMonth = 0;
                if (nowMonth < 3) {
                    quarterStartMonth = 1;
                } else if (2 < nowMonth && nowMonth < 6) {
                    quarterStartMonth = 2;
                } else if (5 < nowMonth && nowMonth < 9) {
                    quarterStartMonth = 3;
                } else if (nowMonth > 8) {
                    quarterStartMonth = 4;
                }
                this.defaultValue = nowYear + '-' + quarterStartMonth;
                this.inputquarter = nowYear + '-' + quarterStartMonth;
                this.bus.$on('quarterchange', (value) => {
                    this.inputquarter = value;
                    this.getData();
                })
            }
            this.getData();
        }
    },
    methods: {
        init: function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth3 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth3, 1)
                this.inputmonth = this.dataFormat(firstDay2);
                this.getData();
            }
            // this.input != '' && this.getData();
        },
        goCustomer(){
            let radio;
            var input
            if (this.radio == 'year') {
                input = this.inputyear;
                radio = 3
            } else if (this.radio == 'month') {
                input = this.inputmonth;
                radio = 2
            } else if (this.radio == 'week') {
                input = this.inputweek;
                radio = 1
            } else if (this.radio == 'quarter') {
                input = this.inputquarter
            }
            this.$router.push({
                path:'/overviewCus',
                query:{
                    type: radio,
                    date: input,
                }
            })
        },
        goOrder(){
            let radio;
            var input
            if (this.radio == 'year') {
                input = this.inputyear;
                radio = 3
            } else if (this.radio == 'month') {
                input = this.inputmonth;
                radio = 2
            } else if (this.radio == 'week') {
                input = this.inputweek;
                radio = 1
            } else if (this.radio == 'quarter') {
                input = this.inputquarter
            }
            this.$router.push({
                path:'/overviewOrder',
                query:{
                    type: radio,
                    date: input,
                }
            })
        },
        getData() {
            let radio;
            var input
            if (this.radio == 'year') {
                input = this.inputyear;
                radio = 3
            } else if (this.radio == 'month') {
                input = this.inputmonth;
                radio = 2
            } else if (this.radio == 'week') {
                input = this.inputweek;
                radio = 1
            } else if (this.radio == 'quarter') {
                input = this.inputquarter
            }
            this.http.post('/admin.statistics/home', {
                type: radio,
                date: input,
            }).then(re => {
                this.detail = re.data;
                for (var i in re.data.line.yAxis) {
                    re.data.line.yAxis[i]['type'] = 'line';
                    re.data.line.yAxis[i]['smooth'] = 'true';
                    re.data.line.yAxis[i]['data'] = re.data.line.yAxis[i]['value'];
                    this.arr2.push(re.data.line.yAxis[i].name)
                }
                this.arr = re.data.line.yAxis;
                this.drawBar();
                this.drawLine();
            })

        },
        // 客户柱形图
        drawBar() {
            var myChartBar = echarts.init(document.getElementById('bar'));
            myChartBar.setOption({
                xAxis: {
                    name: '时间',
                    type: 'category',
                    data: this.detail.site_amount.xAxis
                },
                // legend: {
                //     left: 'right',
                //     type: 'scroll',
                //     top: '10',
                //     height: '100',
                //     data: ['新增客户']
                // },
                yAxis: {
                    type: 'value',
                    name: '金额（元）'
                },
                grid: { //位置
                    left: '3%',
                    right: '40px',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                series: [{
                    data: this.detail.site_amount.yAxis,
                    type: 'bar',
                    name: '金额'
                }]
            })
        },
        // 折线图
        drawLine: function() {
            var myChart = echarts.init(document.getElementById('pie'));
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        // type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                        label: {
                            show: true
                        }
                    },
                },
                legend: {
                    left: 'right',
                    type: 'scroll',
                    top: '10',
                    height: '100',
                    data: this.arr2
                },
                color: ['#c23531', '#e6a23c', '#61a0a8'],
                grid: { //位置
                    left: '3%',
                    right: '40px',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    // axisLabel: {
                    //     interval: 0,
                    //     rotate: 60
                    // },
                    data: this.detail.line.xAxis,
                    name: '时间'
                },
                yAxis: {
                    type: 'value',
                    name: '金额（元）',
                },
                series: this.arr
            });
        },

        dataFormat(time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
        },
    }
}
</script>

<style lang="css" scoped>
</style>
